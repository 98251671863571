/*!
* WS Starter
* Designed by LRS Web Solutions (http://lrswebsolutions.com)
* Visit http://lrswebsolutions.com
*/
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  color: black;
  background: white;
}

.offscreen {
  position: absolute;
  top: -9999px;
  left: -9999px;
}

.hide {
  display: none;
}

.show {
  display: initial;
}

.invisible {
  visibility: hidden;
}

.clear {
  display: block;
  clear: both;
}

.clear-after:after {
  display: table;
  clear: both;
  content: "";
}

.float-center {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

@media (orientation: landscape) {
  .show-landscape {
    display: block;
  }
}
@media (orientation: portrait) {
  .show-landscape {
    display: none;
  }
}

@media (orientation: portrait) {
  .show-portrait {
    display: block;
  }
}
@media (orientation: landscape) {
  .show-portrait {
    display: none;
  }
}

@media (orientation: landscape) {
  .hide-landscape {
    display: none;
  }
}
@media (orientation: portrait) {
  .hide-landscape {
    display: block;
  }
}

@media (orientation: portrait) {
  .hide-portrait {
    display: none;
  }
}
@media (orientation: landscape) {
  .hide-portrait {
    display: block;
  }
}

@media (min-width: 640px) {
  .show-small-only {
    display: none;
  }
}

@media (min-width: 0) and (max-width: 639px) {
  .show-medium-only {
    display: none;
  }
}
@media (min-width: 1024px) {
  .show-medium-only {
    display: none;
  }
}

.show-large-only {
  display: none;
}
@media (min-width: 1024px) and (max-width: 1340px) {
  .show-large-only {
    display: block;
  }
}
@media (min-width: 1341px) {
  .show-large-only {
    display: none;
  }
}

.show-xlarge-only {
  display: none;
}
@media (min-width: 1341px) and (max-width: 1399px) {
  .show-xlarge-only {
    display: block;
  }
}
@media (min-width: 1400px) {
  .show-xlarge-only {
    display: none;
  }
}

.show-xxlarge-only {
  display: none;
}
@media (min-width: 1400px) {
  .show-xxlarge-only {
    display: none;
  }
}

.show-medium-up {
  display: none;
}
@media (min-width: 640px) {
  .show-medium-up {
    display: block;
  }
}

.show-large-up {
  display: none;
}
@media (min-width: 1024px) {
  .show-large-up {
    display: block;
  }
}

.show-xlarge-up {
  display: none;
}
@media (min-width: 1341px) {
  .show-xlarge-up {
    display: block;
  }
}

.show-xxlarge-up {
  display: none;
}
@media (min-width: 1400px) {
  .show-xxlarge-up {
    display: block;
  }
}

@media (min-width: 0) and (max-width: 639px) {
  .hide-small-only {
    display: none;
  }
}

@media (min-width: 640px) and (max-width: 1023px) {
  .hide-medium-only {
    display: none;
  }
}

@media (min-width: 1024px) and (max-width: 1340px) {
  .hide-large-only {
    display: none;
  }
}

@media (min-width: 1341px) and (max-width: 1399px) {
  .hide-xlarge-only {
    display: none;
  }
}

@media (min-width: 1400px) {
  .hide-xxlarge-only {
    display: none;
  }
}

@media (min-width: 640px) {
  .hide-medium-up {
    display: none;
  }
}

@media (min-width: 1024px) {
  .hide-large-up {
    display: none;
  }
}

@media (min-width: 1341px) {
  .hide-xlarge-up {
    display: none;
  }
}

@media (min-width: 1400px) {
  .hide-xxlarge-up {
    display: none;
  }
}

.accordions {
  margin-bottom: 20px;
}
.accordions .accordion-title {
  position: relative;
}
.accordions .accordion-title button {
  position: relative;
  width: 100%;
  margin: 0 0 0.625rem 0;
  padding: 1.25rem 5.125rem 0.625rem 0;
  text-align: left;
  color: black;
  border: 0;
  border-bottom: 2px solid gray;
  background: transparent;
  font-weight: 700;
  font-size: 0.9375rem;
  line-height: 1.125rem;
  letter-spacing: normal;
}
.accordions .accordion-title button span {
  position: absolute;
  right: 0;
  bottom: -2px;
  width: 5rem;
  height: calc(100% + 2px);
  border-bottom: 2px solid gray;
}
.accordions .accordion-title button span:after {
  position: absolute;
  top: 50%;
  left: 2rem;
  content: "\f067";
  transform: translateY(-8px);
  text-align: center;
  text-decoration: inherit;
  color: black;
  font-family: FontAwesome;
  font-size: 1.125rem;
  font-weight: normal;
  font-style: normal;
}
.accordions .accordion-title button:hover {
  cursor: pointer;
}
.accordions .accordion-title button:hover, .accordions .accordion-title button:focus {
  color: blue;
  border: 0;
  border-bottom: 2px solid gray;
  outline: 0;
  background: transparent;
}
.accordions .accordion-title button:hover span, .accordions .accordion-title button:focus span {
  border-bottom: 2px solid gray;
}
.accordions .accordion-title.is-active button, .accordions .accordion-title.is-active button:focus {
  color: blue;
  border: 0;
  border-bottom: 2px solid gray;
  outline: 0;
  background: transparent;
}
.accordions .accordion-title.is-active button span, .accordions .accordion-title.is-active button:focus span {
  border-bottom: 2px solid gray;
}
.accordions .accordion-title.is-active button span:after, .accordions .accordion-title.is-active button:focus span:after {
  content: "\f068";
}
.accordions .accordion-content[aria-hidden] {
  display: none;
}
.accordions .accordion-content[aria-hidden=false] {
  display: block;
}
.accordions.active h2 {
  margin: 0;
}
.tabs {
  position: relative;
  margin-bottom: 1.25rem;
}
.tabs:not(.location-tabs).scroll-left:before, .tabs:not(.location-tabs).scroll-right:after {
  position: absolute;
  z-index: 99;
  width: 2.5rem;
  height: 3.75rem;
  content: "";
}
.tabs:not(.location-tabs).scroll-left:before {
  top: 0;
  left: 0;
  background: linear-gradient(to right, white 0%, rgba(255, 255, 255, 0) 100%);
}
.tabs:not(.location-tabs).scroll-right:after {
  top: 0;
  right: 0;
  background: linear-gradient(to left, white 0%, rgba(255, 255, 255, 0) 100%);
}
.tabs .tab-con {
  position: relative;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  margin-bottom: 2px;
  padding: 0;
  white-space: nowrap;
}
.tabs .tab-list {
  display: none;
  margin: 0;
  padding: 0;
  list-style: none;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 900;
  font-size: 0.875rem;
  line-height: 1.0625rem;
  letter-spacing: normal;
}
.tabs .tab-list[role=tablist] {
  display: inline-block;
}
.tabs .tab-list li {
  display: inline-block;
  margin: 0 3px 0 0;
  padding: 0;
  white-space: nowrap;
}
.tabs .tab-list li:last-child {
  margin: 0;
}
.tabs .tab-list li a {
  position: relative;
  display: block;
  padding: 0.75rem 1.1875rem;
  background: gray;
  color: black;
  text-align: center;
  text-decoration: none;
}
.tabs .tab-list li a:hover, .tabs .tab-list li a[role=tab]:focus {
  outline: 0;
  background: black;
  color: white;
}
.tabs .tab-list li a[aria-selected] {
  border: 0;
  background: gray;
  color: black;
  padding: 0.75rem 1.1875rem;
}
.tabs .tab-list li a[aria-selected]:hover, .tabs .tab-list li a[aria-selected][role=tab]:focus {
  background: gray;
  color: black;
}
.tabs [role=tabpanel][aria-hidden=true] {
  display: none;
}
.tabs [role=tabpanel] .tab-title {
  display: none;
}

table.tablesaw {
  width: 100%;
  max-width: 100%;
  margin: 0 0 1.5rem 0;
  padding: 0;
  empty-cells: show;
  border: 0;
  border-collapse: collapse;
  font-size: 0.875rem;
  line-height: 1.0625rem;
  letter-spacing: normal;
}

.tablesaw * {
  box-sizing: border-box;
}

.tablesaw-stack td .tablesaw-cell-label,
.tablesaw-stack th .tablesaw-cell-label {
  display: none;
}

@media only all {
  .tablesaw-stack {
    clear: both;
  }
  .tablesaw-stack td,
.tablesaw-stack th {
    display: block;
    text-align: left;
  }
  .tablesaw-stack tr {
    display: table-row;
    clear: both;
  }
  .tablesaw-stack td .tablesaw-cell-label,
.tablesaw-stack th .tablesaw-cell-label {
    display: inline-block;
    width: 30%;
    padding: 0 10px 0 0;
  }
  .tablesaw-stack th .tablesaw-cell-label-top,
.tablesaw-stack td .tablesaw-cell-label-top {
    display: block;
    margin: 6px 0;
    padding: 6px 0;
  }
  .tablesaw-stack .tablesaw-cell-label {
    display: block;
  }
  .tablesaw-stack tbody th.group {
    margin-top: -1px;
  }
  .tablesaw-stack th.group b.tablesaw-cell-label {
    display: none !important;
  }
}
@media (max-width: 39.9375em) {
  table.tablesaw-stack {
    margin: 0 -1.25rem 1.5rem -1.25rem;
    width: calc(100% + 40px);
    max-width: calc(100% + 40px);
  }
  table.tablesaw-stack tbody tr {
    display: block;
    width: 100%;
    padding: 0 1.25rem;
  }
  table.tablesaw-stack tbody tr:last-child {
    border-bottom: 1px solid gray;
  }
  table.tablesaw-stack thead {
    display: none;
  }
  table.tablesaw-stack tbody td,
table.tablesaw-stack tbody th {
    display: block;
    clear: left;
    width: 100%;
    padding: 0;
  }
  table.tablesaw-stack tbody td:last-child,
table.tablesaw-stack tbody th:last-child {
    border-bottom: 0;
  }
  table.tablesaw-stack td .tablesaw-cell-label {
    display: inline-block;
    padding: 0.5rem 0.5rem 0.5rem 0;
    text-transform: uppercase;
    vertical-align: top;
    background: transparent;
    font-weight: 700;
  }
  table.tablesaw-stack td .tablesaw-cell-content {
    display: inline-block;
    max-width: 67%;
    padding: 0.5rem 0 0.5rem 0.5rem;
  }
  table.tablesaw-stack .tablesaw-stack-block .tablesaw-cell-label,
table.tablesaw-stack .tablesaw-stack-block .tablesaw-cell-content {
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0;
  }
  table.tablesaw-stack td:empty,
table.tablesaw-stack th:empty {
    display: none;
  }
}
@media (min-width: 40em) {
  .tablesaw-stack tr {
    display: table-row;
  }
  .tablesaw-stack td,
.tablesaw-stack th,
.tablesaw-stack thead td,
.tablesaw-stack thead th {
    display: table-cell;
    margin: 0;
  }
  .tablesaw-stack td .tablesaw-cell-label,
.tablesaw-stack th .tablesaw-cell-label {
    display: none !important;
  }
  .tablesaw-stack thead th {
    padding: 0.625rem 1.125rem 0.5rem;
    color: white;
    text-transform: uppercase;
    font-weight: 700;
  }
  .tablesaw-stack thead th:last-child {
    border-right: 0;
  }
  .tablesaw-stack tbody tr td {
    padding: 0.75rem 1.125rem 0.625rem;
  }
  .tablesaw-stack tbody tr td:last-child {
    border-right: 0;
  }
}
.flex-container {
  width: 100%;
  max-width: 100%;
}
@media (min-width: 1024px) {
  .flex-container.alignleft {
    width: 40%;
    float: left;
    margin: 0 15px 10px 0;
  }
  .flex-container.alignright {
    width: 40%;
    float: right;
    margin: 0 0 10px 15px;
  }
  .flex-container.aligncenter {
    width: 40%;
    margin: 0 auto 10px auto;
  }
}

.flex-video {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0;
}
.flex-video iframe,
.flex-video object,
.flex-video embed,
.flex-video video {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}

fieldset {
  margin-bottom: 20px;
}

input[type=text],
input[type=password],
input[type=email],
textarea,
select {
  border: 1px solid #d8d8d8;
  padding: 10px 11px;
  outline: none;
  font: 14px "Open Sans", sans-serif;
  color: #888;
  margin: 0 0 10px 0;
  width: 100%;
  display: block;
  background: #fff;
  -webkit-box-shadow: 0px 1px 1px 0px rgba(180, 180, 180, 0.1);
  box-shadow: 0px 1px 1px 0px rgba(180, 180, 180, 0.1);
}

.g-recaptcha {
  margin-bottom: 25px;
}

select {
  padding: 0;
}

input[type=text]:focus,
input[type=password]:focus,
input[type=email]:focus,
textarea:focus {
  outline: none !important;
  border: 1px solid #40b1cb;
}

textarea {
  min-height: 60px;
}

label,
legend {
  display: block;
  font-weight: bold;
  font-size: 14px;
  font-weight: normal;
  margin-bottom: 10px;
}

input[type=checkbox] {
  display: inline;
}

label span,
legend span {
  font-weight: normal;
  font-size: 13px;
  color: #444;
}

input[type=submit] {
  color: #fff;
  background: #015b70;
}
input[type=submit]:hover, input[type=submit]:focus, input[type=submit]:visited {
  background: #10819b;
}

.content-form .form-builder-section {
  margin: 0 -13px;
}
.content-form .radio-list ul {
  list-style-type: none;
  padding-left: 0;
}
.content-form .radio-list ul label {
  margin-left: 5px;
}
.content-form .radio-list ul label::after {
  display: none;
}
.content-form .radio-list .error-msg {
  top: 0;
}
@media screen and (min-width: 768px) {
  .content-form .radio-list.pipe-type ul {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 -27px;
  }
}
.content-form .radio-list.pipe-type .error-msg {
  top: 15px;
}
.content-form .radio-list.pipe-type fieldset {
  width: 100%;
}
@media screen and (min-width: 768px) {
  .content-form .radio-list.pipe-type li {
    width: 50%;
    padding-right: 1.6875rem;
    padding-left: 1.6875rem;
  }
}
@media screen and (min-width: 64rem) {
  .content-form .radio-list.pipe-type li {
    width: 25%;
    padding-right: 1.6875rem;
    padding-left: 1.6875rem;
  }
}
.content-form .radio-list.pipe-type li label {
  position: relative;
  margin-bottom: 135px;
}
.content-form .radio-list.pipe-type li label::before {
  position: absolute;
  top: 20px;
  left: 0;
  width: 200px;
  height: 117px;
  background: #303030;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  content: "";
}
.content-form .radio-list.pipe-type li:nth-child(1) label::before {
  background-image: url(/themes/currangardner/assets/dist/images/img-galvanized-steel.jpg);
}
.content-form .radio-list.pipe-type li:nth-child(2) label::before {
  background-image: url(/themes/currangardner/assets/dist/images/img-lead.jpg);
}
.content-form .radio-list.pipe-type li:nth-child(3) label::before {
  background-image: url(/themes/currangardner/assets/dist/images/img-coppere.jpg);
}
.content-form .radio-list.pipe-type li:nth-child(4) label::before {
  background-image: url(/themes/currangardner/assets/dist/images/img-plastic.jpg);
}
.content-form .radio-list.pipe-type li:nth-child(5) label {
  margin-bottom: 0;
}
.content-form .radio-list.pipe-type li:nth-child(5) label::before {
  display: none;
}
.content-form .required label::after {
  color: #ff0000;
}

.plugin .field-group {
  display: flex;
  justify-content: space-between;
}
.plugin .field-group input[type=text] {
  flex: 1;
  margin-right: 10px;
}
.plugin .field-group input[type=text]:last-of-type {
  margin-right: 0;
}
.plugin .alert.error {
  color: #fff;
  background-color: #a11a06;
  padding: 20px;
  margin-bottom: 20px;
}
.plugin .alert.error ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

.list-1 li,
.list-2 li,
.list-3 li,
.list-4 li {
  list-style: none;
  margin: 7px 0;
  margin-left: 2px;
}
.list-1 li:first-child,
.list-2 li:first-child,
.list-3 li:first-child,
.list-4 li:first-child {
  margin-top: 0;
}
.list-1 li::before,
.list-2 li::before,
.list-3 li::before,
.list-4 li::before {
  content: "\f00c";
  font-family: "FontAwesome";
  margin: 0 7px 0 0;
  position: relative;
  color: #B9B53F;
}

.list-1 li::before {
  content: "\f00c";
}

.list-2 li::before {
  content: "\f054";
}

.list-3 li::before {
  content: "\f058";
}

.list-4 li::before {
  content: "\f138";
}