fieldset {
  margin-bottom: 20px; 
}

input[type="text"],
input[type="password"],
input[type="email"],
textarea,
select {
  border: 1px solid #d8d8d8;
  padding: 10px 11px;
  outline: none;
  font: 14px "Open Sans", sans-serif;
  color: #888;
  margin: 0 0 10px 0;
  width: 100%;
  display: block;
  background: #fff;
  -webkit-box-shadow:  0px 1px 1px 0px rgba(180, 180, 180, 0.1);
  box-shadow:  0px 1px 1px 0px rgba(180, 180, 180, 0.1);
}

.g-recaptcha {
  margin-bottom: 25px;
}

select {
  padding: 0;
}

input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus,
textarea:focus {
  outline: none !important;
  border: 1px solid $java;
}

textarea {
  min-height: 60px; 
}

label,
legend {
  display: block;
  font-weight: bold;
  font-size: 14px; 
  font-weight: normal;
  margin-bottom: 10px;
}

input[type="checkbox"] {
  display: inline; 
}

label span,
legend span {
  font-weight: normal;
  font-size: 13px;
  color: #444; 
}

input[type='submit'] {
  color:#fff; 
  background: $blue-stone;

  &:hover,
  &:focus,
  &:visited {
    background: $blue-lagoon;
  }
}

.content-form {
  .form-builder-section {
    margin: 0 -13px;
  }

  .radio-list {
    ul {
      list-style-type: none;
      padding-left: 0;

      label {
        margin-left: 5px;

        &::after {
          display: none;
        }
      }
    }

    .error-msg {
      top: 0;
    }

    &.pipe-type {
      ul {
        @media screen and (min-width: 768px) {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          margin: 0 -27px;
        }
      }

      .error-msg {
        top: 15px;
      }
  
      fieldset {
        width: 100%;
      }
  
      li {
        @media screen and (min-width: 768px) {
          width: 50%;
          padding-right: rem-calc(27px);
          padding-left: rem-calc(27px);
        }
  
        @media screen and (min-width: 64rem) {
          width: 25%;
          padding-right: rem-calc(27px);
          padding-left: rem-calc(27px);
        }
  
        label {
          position: relative;
          margin-bottom: 135px;
  
          &::before {
            position: absolute;
            top: 20px;
            left: 0;
            width: 200px;
            height: 117px;
            background: #303030;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            content: '';
          }
        }
  
        &:nth-child(1) {
          label {
            &::before {
              background-image: url(/themes/currangardner/assets/dist/images/img-galvanized-steel.jpg);
            }
          }
        }
  
        &:nth-child(2) {
          label {
            &::before {
              background-image: url(/themes/currangardner/assets/dist/images/img-lead.jpg);
            }
          }
        }
  
        &:nth-child(3) {
          label {
            &::before {
              background-image: url(/themes/currangardner/assets/dist/images/img-coppere.jpg);
            }
          }
        }
  
        &:nth-child(4) {
          label {
            &::before {
              background-image: url(/themes/currangardner/assets/dist/images/img-plastic.jpg);
            }
          }
        }
  
        &:nth-child(5) {
          label {
            margin-bottom: 0;
            &::before {
              display: none;
            }
          }
        }
      }
    }
  }

  .required label::after {
    color: #ff0000
  }

  

  // .pt {
  //   label {
  //     position: relative;
  //     margin-bottom: 135px;

  //     &::before {
  //       position: absolute;
  //       top: 20px;
  //       left: 0;
  //       width: 200px;
  //       height: 117px;
  //       background: #303030;
  //       background-repeat: no-repeat;
  //       background-size: cover;
  //       background-position: center;
  //       content: '';
  //     }
  //   }

  //   &__lead {
  //     label {
  //       &::before {
  //         background-image: url(/themes/currangardner/assets/dist/images/img-lead.jpg);
  //       }
  //     }
  //   }

  //   &__copper {
  //     label {
  //       &::before {
  //         background-image: url(/themes/currangardner/assets/dist/images/img-coppere.jpg);
  //       }
  //     }
  //   }

  //   &__plastic {
  //     label {
  //       &::before {
  //         background-image: url(/themes/currangardner/assets/dist/images/img-plastic.jpg);
  //       }
  //     }
  //   }

  //   &__galvanized {
  //     label {
  //       &::before {
  //         background-image: url(/themes/currangardner/assets/dist/images/img-galvanized-steel.jpg);
  //       }
  //     }
  //   }

  // }
}

// Pay my bill

.plugin {

  .field-group {
    display: flex;
    justify-content: space-between;
  
    input[type='text'] {
      flex:1;
      margin-right: 10px;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  .alert.error {
    color: #fff;
    background-color: #a11a06;
    padding: 20px;
    margin-bottom: 20px;

    ul {
      list-style: none;
      padding-left: 0;
      margin-bottom: 0;
    }
  }

}
