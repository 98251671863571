.list-1,
.list-2,
.list-3,
.list-4 {

  li {
    list-style: none;
    margin: 7px 0;
    margin-left: 2px;

    &:first-child {
      margin-top: 0;
    }

    &::before {
      content: "";
      font-family: "FontAwesome";
      margin: 0 7px 0 0;
      position: relative;
      color: $roti;
    }
  }

}

.list-1 li::before { 
  content: "";
}

.list-2 li::before { 
  content: ""; 
}

.list-3 li::before { 
  content: ""; 
}

.list-4 li::before { 
  content: ""; 
}